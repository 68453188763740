
import { Component, Watch, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FetchAllParams } from '../models/shared/fetch-all-params.interface';
import Course from '@/models/courses/course.class';
import CourseItem from '../components/CourseItem.vue';
import FilterCourses from '../components/FilterCourses.vue';
import Language from '../models/languages/language.class';
import Category from '@/models/categories/category.class';
import { Filters } from '../models/app/filters.interface';
import { UsePageHeaderMixin } from '../mixins/use-page-header.mixin';
import { translateApi } from '../utils/translate-api';
import { UseCurrentLocaleMixin } from '../mixins/use-current-locale.mixin';
import { FilteredCourse } from '@/models/courses/filtered-course.interface';
import { createRouteWithLocaleParam } from '@/utils/create-route-with-locale-param';
import { SortOrder } from '@/enums/sort-order.enum';
import { PaginatedResponse } from '@/models/shared/paginated-response.interface';

const coursesModule = namespace('courses');
const locationsModule = namespace('locations');
const languagesModule = namespace('languages');
const categoriesModule = namespace('categories');

@Component({
  components: {
    CourseItem,
    FilterCourses,
  },
  filters: {
    translateApi,
  },
})
export default class Courses extends Mixins(UsePageHeaderMixin, UseCurrentLocaleMixin) {
  isLoading = false;
  page = 1;
  paginatedCourses: FilteredCourse[] = [];
  totalPages = 0;
  itemsPerPage = 20;
  infiniteId: number = +new Date();
  isAscending = false;
  isDateAsc = false;
  sorted = false;
  dateSorting = false;
  titleSorting = false;
  i = 0;

  @coursesModule.Action('fetchFilteredCourses')
  fetchCourses!: (payload?: FetchAllParams) => Promise<PaginatedResponse<FilteredCourse[]>>;

  @coursesModule.Action('clearData')
  clearData!: () => void;

  @coursesModule.Getter('all')
  allCourses!: PaginatedResponse<FilteredCourse>;

  @coursesModule.Getter('apiParams')
  apiParams!: FetchAllParams;

  @locationsModule.Action('FETCH_UNPAGINATED')
  fetchLocations!: (payload?: FetchAllParams) => Course[];

  @locationsModule.Getter('ALL')
  locations!: Location[];

  @categoriesModule.Action('FETCH_UNPAGINATED')
  fetchCategories!: (payload?: FetchAllParams) => Category[];

  @categoriesModule.Getter('ALL')
  categories!: Category[];

  @languagesModule.Action('FETCH_UNPAGINATED')
  fetchLanguages!: (payload?: FetchAllParams) => Language[];

  @languagesModule.Getter('ALL')
  languages!: Language[];

  get headerHtml() {
    return `<h2 class="text-white">${this.$t('course.title')}</h2>`;
  }

  @Watch('$route.query', { immediate: true })
  async onQueryChanged() {
    const searchQuery = this.$route.query.searchQuery;
    let apiParams: FetchAllParams | undefined;
    if (searchQuery && typeof searchQuery === 'string') {
      apiParams = { search: searchQuery, page: undefined };
      await this.fetchFilteredCourses(apiParams);
      return;
    }
    this.apiParams.sort = [`session.startDate,${SortOrder.Desc}`];
    this.sortOnSessionDate();
  }

  async search(params: Filters) {
    const apiParams: FetchAllParams = { ...this.apiParams, filters: params, page: undefined };
    await this.fetchFilteredCourses(apiParams);
  }

  async fetchFilteredCourses(apiParams?: FetchAllParams) {
    this.isLoading = true;
    this.sorted = false;
    await this.fetchCourses(apiParams);
    this.infiniteId += 1;
    this.page = 1;
    this.paginatedCourses = this.allCourses.docs;
    this.totalPages = this.allCourses.pages;
    this.isLoading = false;
  }

  async getFilterData() {
    this.fetchLocations();
    this.fetchLanguages();
    this.fetchCategories();
    this.fetchLocations();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async loadMoreCourses($state: any) {
    if (this.page < this.totalPages) {
      this.page += 1;
      const params = { ...this.apiParams, page: this.page };
      await this.fetchCourses(params);
      this.paginatedCourses.push(...this.allCourses.docs);
      $state.loaded();
    } else {
      $state.complete();
    }
  }

  openCourseDetail(course: FilteredCourse, session: string) {
    const route = createRouteWithLocaleParam('course', this.localeCode, {
      slug: translateApi(course.slug, this.$i18n.locale),
      selectedSessionId: course.session && session,
    });
    this.$router.push(route);
  }

  sortOnCourseTitle() {
    let sort: string[] = [`title.${this.localeCode},${SortOrder.Asc}`];
    this.sorted = true;
    this.dateSorting = false;
    this.titleSorting = true;
    this.isAscending = true;
    if (this.apiParams.sort && this.apiParams.sort.includes(`title.${this.localeCode},ASC`)) {
      sort = [`title.${this.localeCode},${SortOrder.Desc}`];
      this.isAscending = false;
    }
    const params: FetchAllParams = { ...this.apiParams, sort: sort, page: 1 };
    this.fetchFilteredCourses(params);
  }

  sortOnSessionDate(): void {
    let sort: string[] = [`session.startDate,${SortOrder.Asc}`];
    this.dateSorting = true;
    this.titleSorting = false;
    this.isDateAsc = !this.isDateAsc;
    if (this.apiParams.sort && this.apiParams.sort.includes('session.startDate,ASC')) {
      sort = [`session.startDate,${SortOrder.Desc}`];
    }
    const params: FetchAllParams = { ...this.apiParams, sort: sort, page: 1 };
    this.fetchFilteredCourses(params);
  }

  async created() {
    await this.getFilterData();
  }

  destroyed() {
    this.clearData();
  }
}
