
import { Component, Vue, Prop } from 'vue-property-decorator';
import { translateApi } from '../utils/translate-api';
import moment from 'moment';
import { FilteredCourse } from '../models/courses/filtered-course.interface';
import { TranslatedProperty } from '@/models/shared/translated-property.class';

@Component({
  components: {},
  filters: {
    translateApi,
  },
})
export default class CourseItem extends Vue {
  badgeColor = '';

  @Prop({ type: Object })
  course!: FilteredCourse;

  @Prop({ type: Boolean })
  past!: boolean;

  formattedDate(date: string) {
    moment.locale('nl-be');
    if (!date) {
      return '';
    }
    return moment(date).format('L');
  }

  get locationString() {
    if (!this.course.session) {
      return '';
    }

    const { location, isOnline } = this.course.session;

    if (isOnline) {
      return this.$t('course.onlineLocation');
    }

    if (location && location[0]) {
      return `${translateApi(location[0].name, this.$i18n.locale)}, <br/>${translateApi(
        location[0].address,
        this.$i18n.locale,
      )}, <br />
        ${location[0].zipCode} ${translateApi(location[0].city, this.$i18n.locale)}`;
    }

    return '';
  }

  openCourseDetail() {
    this.$emit('click', this.course);
  }

  checkLength(shortDescription: TranslatedProperty) {
    const description = translateApi(shortDescription, this.$i18n.locale);
    if (description.length > 100) {
      return 'fade';
    }
    return '';
  }
}
