
import { Component, Prop, Watch, Emit, Mixins } from 'vue-property-decorator';
import { FilterOperation } from '../models/app/filter-operation.enum';
import { translateApi } from '../utils/translate-api';
import Location from '@/models/locations/location.class';
import { UseViewWidthHelpersMixin } from '@/mixins/use-view-width-helpers.mixin';
import Language from '@/models/languages/language.class';
import Category from '@/models/categories/category.class';
import { Filters } from '../models/app/filters.interface';
import { CourseType } from '@/enums/course-type.enum';

@Component({
  components: {},
  filters: { translateApi },
})
export default class FilterCourses extends Mixins(UseViewWidthHelpersMixin) {
  @Prop({ type: Array })
  languages!: Language[];

  @Prop({ type: Array })
  categories!: Category[];

  @Prop({ type: Array })
  locations!: Location[];

  @Prop({ type: Object })
  initialFilter!: Filters;

  courseType = CourseType;

  isVisible = true;

  coursesFilter: Filters = {
    languageIds: {
      value: [],
      operation: FilterOperation.Equals,
    },
    types: {
      value: [],
      operation: FilterOperation.Equals,
    },
    categoryIds: {
      value: [],
      operation: FilterOperation.Equals,
    },
    locationIds: {
      value: [],
      operation: FilterOperation.Equals,
    },
  };

  @Watch('coursesFilter', { deep: true })
  onFilterChanged() {
    this.onFilter();
  }

  @Emit('filter')
  onFilter() {
    return this.coursesFilter;
  }

  created() {
    if (this.initialFilter?.types) {
      this.coursesFilter.types = { ...this.initialFilter.types };
    }
    if (this.isExtraSmallDevice || this.isSmallDevice || this.isMediumDevice) {
      this.isVisible = false;
    }
  }
}
